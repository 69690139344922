var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","mini-variant":"","flat":"","dark":"","color":_vm.appbarColor,"fixed":"","extended":_vm.haveFarm,"extension-height":_vm.extensionHeight},scopedSlots:_vm._u([(_vm.haveFarm)?{key:"extension",fn:function(){return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"100%"}},[_c('AppNavigationBar')],1)]},proxy:true}:null],null,true)},[(!_vm.drawer)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer($event)}}}):_vm._e(),_c('IconBase',{staticClass:"rcLogoIcon",attrs:{"name":'rclogo',"width":1998,"height":431}},[_c('AppLogo')],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleTheme($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1)]}}])},[_c('span',[_vm._v("Cambia Tema")])]),(!_vm.isLogged)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{ name: 'Login', params: { lang: _vm.$i18n.locale } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-login")])],1)]}}],null,false,802627085)},[_c('span',[_vm._v("Accedi")])]):_vm._e(),(_vm.isLogged)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,1415903684)},[_c('v-list',_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,on:{"click":_vm.userAction}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }