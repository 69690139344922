<template>
    <v-app-bar
        app
        mini-variant
        flat
        dark
        :color="appbarColor"
        fixed
        :extended="haveFarm"
        :extension-height="extensionHeight"
    >
        <v-app-bar-nav-icon
            v-if="!drawer"
            @click.stop="toggleDrawer"
        ></v-app-bar-nav-icon>
        <IconBase
            class="rcLogoIcon"
            :name="'rclogo'"
            :width="1998"
            :height="431"
            ><AppLogo
        /></IconBase>
        <v-spacer></v-spacer>
        <!-- <SwitchLocale></SwitchLocale> -->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click.stop="toggleTheme" v-bind="attrs" v-on="on">
                    <v-icon>mdi-theme-light-dark</v-icon>
                </v-btn>
            </template>
            <span>Cambia Tema</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!isLogged">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :to="{ name: 'Login', params: { lang: $i18n.locale } }"
                >
                    <v-icon>mdi-login</v-icon>
                </v-btn>
            </template>
            <span>Accedi</span>
        </v-tooltip>
        <v-menu v-if="isLogged" bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-account</v-icon>
                </v-btn>
            </template>

            <v-list>
                <!-- <v-list-item v-for="(item, i) in items" :key="i" link :to="item.to"> -->
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    @click="userAction"
                >
                    <v-list-item-action>
                        <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <template v-slot:extension v-if="haveFarm">
            <div class="d-flex flex-column" style="width:100%">
                <AppNavigationBar></AppNavigationBar>
            </div>
        </template>
    </v-app-bar>
</template>

<script>
export const AppLogo = () => import("./icons/RCLogo");
export const IconBase = () => import("./IconBase");
export const SwitchLocale = () => import("./LocaleSwitcher");
export const AppNavigationBar = () => import("../NavigationBar");

import { mapActions, mapMutations, mapState } from "vuex";

import * as appActions from "../../store/actions/app";
import * as userActions from "../../store/actions/user";
import { isEmptyObj } from "../../utils/utils";

export default {
    data() {
        return {
            items: [
                // { title: "Account", to: "/account" },
                // { title: "Profilo", to: "/profile" },
                { icon: 'mdi-logout', title: "Esci" }
            ]
        };
    },

    components: {
        AppLogo,
        IconBase,
        /* SwitchLocale, */
        AppNavigationBar,
    },

    computed: {
        ...mapState({
            drawer: state => state.appModule.drawer,
            isLogged: state => state.userModule.logged,
            appbarColor: state => state.appModule.appbarColor,
            currentFarm: state => state.farmModule.currentFarm
        }),

        haveFarm() {
            return !isEmptyObj(this.currentFarm) || this.$route.name === 'Multi' || this.$route.name === 'MultiPromo';
        },

        extensionHeight() {
            return 48
        }
    },

    methods: {
        ...mapMutations({
            toggleDrawer: appActions.APP_TOGGLEDRAWERSTATUS
        }),

        ...mapActions({
            doLogout: userActions.USER_DOLOGOUT
        }),

        toggleTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        },

        userAction() {
            // console.log("fake dick");
            this.doLogout().then(() => {
                this.$router.push({
                    name: "Login",
                    params: { lang: this.$i18n.locale }
                });
            });
        }
    }
};
</script>

<style lang="css">
.v-toolbar__title {
    cursor: default;
    user-select: none;
}

.rcLogoIcon {
    height: 32px;
    padding-left: 16px;
}
</style>
